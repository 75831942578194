.selectorLayout {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	row-gap: 0.5em;
}

.selectorLayout .selectorButton {
	background: linear-gradient(0deg, rgba(227, 85, 255, 0.1), rgba(227, 85, 255, 0.1)), linear-gradient(180deg, #08326D 0%, #0E4281 100%);
	border: 1px solid #32578A;
	box-shadow: inset 0px 0px 0px 8px rgba(232, 235, 243, 0.03);
	border-radius: 12px;
	font-style: normal;
	font-weight: 400;
	font-size: 30px;
	color: #FFFFFF;
	padding: 2px 10px;
	font-family: monospace;
	cursor: pointer;
}