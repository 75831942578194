.modalContainerLayout {
	width: 100vw;
	height: 100vh;
	background-color: rgba(0, 0, 0, 0.5);
	display: flex;
	justify-content: center;
	align-items: center;
}

.modalContainerLayout .modalView {
	position: relative;
	padding: 24px;
	isolation: isolate;
	min-width: 312px;
	background: radial-gradient(100% 478.79% at 100% -3.42%, #413B6F 0%, #2C2847 100%);
	box-shadow: 0px 10px 10px -3px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.2);
}

.modalContainerLayout .modalView .closeButton {
	position: absolute;
	top: 13px;
	right: 13px;
	cursor: pointer;
}