.inputPastebleLayout {
	width: 100%;
}

.inputPastebleLayout .title {
	font-style: normal;
	font-weight: 700;
	font-size: 14px;
	line-height: 14px;
	text-transform: capitalize;
	color: #F7F6F9;
	margin-bottom: 10px;
}

.inputPastebleLayout .inputBlock {
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 4px 4px 4px 12px;
	gap: 8px;
	background: linear-gradient(72.48deg, #1E1B3D 0%, #2C2847 100%);
	box-shadow: inset 0px 0px 1px rgba(0, 0, 0, 0.4), inset 0px 1px 2px rgba(0, 0, 0, 0.3), inset 0px 10px 10px -3px rgba(0, 0, 0, 0.2);
	flex: 1 1;
}

.inputPastebleLayout input {
	background-color: transparent;
	outline: none;
	border: none;
	outline: none;
	width: 100%;
	font-family: 'Urbanist', sans-serif;
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	letter-spacing: 0.02em;
	color: #A5A0BB;
	padding: 17px 12px;
}

.inputPastebleLayout .paste {
	font-style: normal;
	font-weight: 700;
	font-size: 16px;
	letter-spacing: 0.02em;
	color: #CCB7F8;
	padding: 16px;
	text-transform: capitalize;
	cursor: pointer;
}