.voteCardLayout {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding: 16px;
	gap: 16px;
	background: radial-gradient(100% 478.79% at 100% -3.42%, #413B6F 0%, #2C2847 100%);
	border-radius: 2px;
	flex: 0 1 auto;
	width: 100%;
}