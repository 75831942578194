.valueAndKeyLayout {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	width: fit-content;
	gap: 8px;
}

.valueAndKeyLayout .value {
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	text-align: right;
	letter-spacing: 0.02em;
	color: #F7F6F9;
	line-height: 1;
}

.valueAndKeyLayout .key {
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	letter-spacing: 0.02em;
	color: #A5A0BB;
	text-transform: capitalize;
	line-height: 1;
}