.valueUpdateLayout {
	display: flex;
	flex-direction: row;
	align-items: center;
	column-gap: 6px;
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	text-align: right;
	letter-spacing: 0.02em;
	color: #F7F6F9;
}