body {
  margin: 0;
  font-family: 'Urbanist', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 12px;
  background-image: url("./assets/images/bg.png");
  background-color: #2c2847;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right top;
  color: #F7F6F9;
}

h1 {
  font-style: normal;
  font-weight: 700;
  font-size: 56px;
  letter-spacing: -0.01em;
  text-transform: capitalize;
  margin: 0;
  padding: 0;
}

h2 {
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  text-transform: capitalize;
  color: #F7F6F9;
  margin: 0;
  padding: 0;
}

h3 {
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  letter-spacing: -0.01em;
  text-transform: capitalize;
  color: #F7F6F9;
  margin: 0;
  padding: 0;
}

h5 {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  text-transform: capitalize;
  color: #F7F6F9;
  margin: 0;
  padding: 0;
}

h6 {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  text-transform: capitalize;
  color: #F7F6F9;
  margin: 0;
  padding: 0;
}

p {
  line-height: 1;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@media (prefers-color-scheme: dark) {
  /*  */
}

@media (prefers-color-scheme: light) {
  /*  */
}