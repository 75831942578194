.labelLayout {
	padding: 1em;
	background: linear-gradient(0deg, rgba(227, 85, 255, 0.1), rgba(227, 85, 255, 0.1)), linear-gradient(180deg, #08326D 0%, #0E4281 100%);
	border: 1px solid #32578A;
	box-shadow: inset 0px 0px 0px 8px rgba(232, 235, 243, 0.03);
	border-radius: 12px;
	font-family: 'Days One', sans-serif;
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	text-transform: uppercase;
	color: #FFFFFF;
}