.amountInputLayout {
	width: 100%;
}

.amountInputLayout .titleLine {
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: baseline;
	margin-bottom: 10px;
}

.amountInputLayout .titleLine .name {
	font-style: normal;
	font-weight: 700;
	font-size: 14px;
	text-transform: capitalize;
	color: #F7F6F9;
}

.amountInputLayout .titleLine .balance {
	font-style: normal;
	font-weight: 700;
	font-size: 12px;
	line-height: 12px;
	letter-spacing: 0.02em;
	color: #F7F6F9;
}

.amountInputLayout .inputBlock {
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 4px;
	gap: 8px;
	background: linear-gradient(72.48deg, #1E1B3D 0%, #2C2847 100%);
	box-shadow: inset 0px 0px 1px rgba(0, 0, 0, 0.4), inset 0px 1px 2px rgba(0, 0, 0, 0.3), inset 0px 10px 10px -3px rgba(0, 0, 0, 0.2);
}

.amountInputLayout .inputBlock .amountInputStyle {
	appearance: textfield;
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	letter-spacing: 0.02em;
	color: #F7F6F9;
	background-color: transparent;
	border: none;
	outline: none;
	width: 100%;
	flex: 1 1;
}

.amountInputLayout .inputBlock .amountInputLogo {
	/*  */
}

.amountInputLayout .maxButton {
	font-style: normal;
	font-weight: 700;
	font-size: 16px;
	letter-spacing: 0.02em;
	color: #CCB7F8;
	padding: 10px;
	text-transform: capitalize;
	cursor: pointer;
}