.registerNodeModalLayout {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	max-width: 344px;
	width: 100%;
}

.registerNodeModalLayout .modalContent {
	display: flex;
	flex-direction: column;
	row-gap: 24px;
	justify-content: flex-start;
	align-items: flex-start;
	width: 100%;
}

.registerNodeModalLayout .values {
	width: calc(100% - 32px);
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 16px;
	row-gap: 10px;
	background: #2C2847;
	box-shadow: 0px 10px 10px -3px rgba(0, 0, 0, 0.1), 0px 2px 2px rgba(0, 0, 0, 0.1);
}

.registerNodeModalLayout .text {
	font-family: 'Rubik', sans-serif;
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	color: #F8FAFF;
}

.registerNodeModalLayout .label {
	font-family: 'Rubik', sans-serif;
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	color: #ABB7CA;
}