.linkStyle {
	font-style: normal;
	font-weight: 700;
	font-size: 14px;
	letter-spacing: 0.02em;
	color: #F7F6F9;
	text-decoration: none;
	text-transform: capitalize;
}

.linkStylePrimary {
	font-style: normal;
	font-weight: 700;
	font-size: 14px;
	letter-spacing: 0.02em;
	color: #CCB7F8;
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 7px;
}