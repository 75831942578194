.nodeCardLayout {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding: 16px;
	gap: 16px;
	background: radial-gradient(100% 478.79% at 100% -3.42%, #413B6F 0%, #2C2847 100%);
	box-shadow: 0px 10px 10px -3px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.2);
	border-radius: 2px;
}

.nodeCardLayout .buttons {
	display: flex;
	flex-direction: row;
	gap: 8px;
	width: 100%;
}

.nodeCardLayout .titalBar {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	width: 100%;
}

.nodeCardLayout .titalBar .healthLabel :first-child {
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
	letter-spacing: 0.02em;
	color: #A5A0BB;
	text-transform: capitalize;
}

.nodeCardLayout .titalBar .healthLabel :last-child {
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	letter-spacing: 0.02em;
	color: #E4BC62;
	margin-left: 4px;
}

.nodeCardLayout .titalBar .leftSide {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
}

.nodeCardLayout .titalBar .leftSide .title {
	font-style: normal;
	font-weight: 700;
	font-size: 16px;
	text-transform: capitalize;
	color: #F7F6F9;
}

.nodeCardLayout .values {
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	align-items: center;
	margin: 1em 0 2em 0;
	width: 100%;
	column-gap: 1em;
}