.buttonDefault {
	cursor: pointer;
	padding: 8px;
	gap: 8px;
	background: radial-gradient(100% 599.48% at 100% 0%, #4C4577 0%, #413B6F 100%);
	box-shadow: 0px 10px 10px -3px rgba(0, 0, 0, 0.1), 0px 2px 2px rgba(0, 0, 0, 0.1);
	border-radius: 2px;
	border: none;
	outline: none;
	height: 40px;
	min-width: 40px;
	color: #F7F6F9;
	font-family: 'Urbanist', sans-serif;
	font-style: normal;
	font-weight: 700;
	font-size: 16px;
	letter-spacing: 0.02em;
	color: #CCB7F8;
	text-transform: capitalize;
}

.buttonSmall {
	padding: 11px;
}

.buttonPrimary {
	font-family: 'Urbanist', sans-serif;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	padding: 12px;
	gap: 8px;
	background: radial-gradient(100% 592% at 100% 0%, #CCB7F8 0%, #B292F4 100%);
	box-shadow: 0px 10px 10px -3px rgba(0, 0, 0, 0.1), 0px 2px 2px rgba(0, 0, 0, 0.1);
	border-radius: 2px;
	font-style: normal;
	font-weight: 700;
	font-size: 20px;
	letter-spacing: 0.02em;
	color: #1E1B3D;
	cursor: pointer;
	border: none;
	outline: none;
	text-transform: capitalize;
}

.deprecated {
	color: #CCB7F8;
}

.buttonSecondary {
	padding: 12px;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 8px;
	background: radial-gradient(100% 599.48% at 100% 0%, #4C4577 0%, #413B6F 100%);
	box-shadow: 0px 10px 10px -3px rgba(0, 0, 0, 0.1), 0px 2px 2px rgba(0, 0, 0, 0.1);
	border-radius: 2px;
	font-style: normal;
	font-weight: 700;
	font-size: 16px;
	letter-spacing: 0.02em;
	color: #CCB7F8;
	text-transform: capitalize;
	cursor: pointer;
	border: none;
	outline: none;
}

.noBackground {
	padding: 12px;
	gap: 8px;
	box-shadow: 0px 10px 10px -3px rgba(0, 0, 0, 0.1);
	filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.1));
	border-radius: 2px;
	font-family: 'Urbanist', sans-serif;
	font-style: normal;
	font-weight: 700;
	font-size: 16px;
	letter-spacing: 0.02em;
	color: #CCB7F8;
	text-transform: capitalize;
	background-color: transparent;
	outline: none;
	border: none;
	cursor: pointer;
}

.buttonImportant {
	background: linear-gradient(221.16deg, rgba(37, 180, 255, 0.6) 1.63%, rgba(93, 91, 255, 0.6) 51.7%, rgba(199, 36, 255, 0.6) 105.55%);
	border-radius: 16px;
	font-family: 'Days One', sans-serif;
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	text-transform: uppercase;
	color: #FFFFFF;
	padding-top: 19px;
	padding-bottom: 19px;
	border: 1px solid;
	border-image-source: linear-gradient(221.16deg, #25B4FF 1.63%, #5D5BFF 51.7%, #C724FF 105.55%);
	cursor: pointer;
}

button:disabled {
	cursor: not-allowed;
}

button {
	display: flex;
	gap: 8px;
	justify-content: center;
	align-items: center;
}